import jQuery from 'jquery';

const subscribeFormToggler = () => {
  jQuery('.js-subscribe-toggler').on('click', function subscribeFormToggle() {
    const formContainer = jQuery('.js-form-replace');
    const actionUrl = formContainer.data('action-url');
    const placeholderFirstName = formContainer.data('placeholder-first-name');
    const placeholderLastName = formContainer.data('placeholder-last-name');
    const placeholderEmail = formContainer.data('placeholder-email');
    const consentText = formContainer.data('consent-text');

    // Define the disabled class for buttons when consent is required
    const disabledButtonClass = consentText ? 'c-subscribe__button--disabled' : '';

    const formHtml = `
      <form
          class=""
          action="${actionUrl}"
          method="POST"
          data-gtm-name="Subscribe"
      >
          ${consentText ? `
            <div class="c-form-checkbox c-subscribe__consent c-subscribe__consent--desktop c-subscribe__fieldset">
                <input
                  class="c-form-checkbox__input js-consent-checkbox"
                  id="consent-desktop"
                  name="consent"
                  type="checkbox"
                >

                <label class="c-form-checkbox__label" for="consent-desktop">${consentText}</label>
            </div>
          ` : ''}

          <div class="c-subscribe__fieldset">
              <div class="c-subscribe__field">
                  <input 
                    type="text" 
                    name="firstName" 
                    class="js-cm-first-name-input" 
                    id="fieldFirstName" 
                    placeholder="${placeholderFirstName}" 
                    autofocus required=""
                  >
              </div>

              <div class="c-subscribe__field">
                  <input 
                    type="text" 
                    name="lastName" 
                    class="js-cm-last-name-input" 
                    id="fieldLastName" 
                    placeholder="${placeholderLastName}"
                    autofocus 
                    required=""
                  >
              </div>

              <div class="c-subscribe__field">
                  <input 
                    type="email" 
                    name="email" 
                    class="js-cm-email-input" 
                    id="fieldEmail" 
                    autofocus 
                    placeholder="${placeholderEmail}" 
                    required=""
                  >
              </div>

              ${consentText ? `
                <div class="c-form-checkbox c-subscribe__consent c-subscribe__consent--mobile">
                    <input
                      class="c-form-checkbox__input js-consent-checkbox"
                      id="consent-mobile"
                      name="consent"
                      type="checkbox"
                    >

                    <label class="c-form-checkbox__label" for="consent-mobile">${consentText}</label>
                </div>
              ` : ''}

              <div class="c-subscribe__button__mobile ${disabledButtonClass}">
                  <button type="submit" ${consentText ? 'disabled' : ''}>${window.location.pathname.includes('/cn/') ? '提交' : 'Submit'}</button>
              </div>

              <div class="c-subscribe__button ${disabledButtonClass}">
                  <button type="submit" class="svg-arrow-right" ${consentText ? 'disabled' : ''}>
                      <svg>
                          <use xlink:href="#arrow-right"></use>
                      </svg>
                  </button>
              </div>
          </div>
      </form>
    `;

    formContainer.replaceWith(formHtml);

    jQuery(this).parent().parent().find('.c-subscribe__fieldset')
      .toggleClass('active');
    jQuery(this).parent().parent().find('.js-cm-first-name-input')
      .focus();

    // Add event listener for consent checkboxes if consent is required
    if (consentText) {
      jQuery('.js-consent-checkbox').on('change', () => {
        // Check if at least one consent checkbox is checked
        const isAnyConsentChecked = jQuery('.js-consent-checkbox:checked').length > 0;

        // Toggle disabled class and attribute based on checkbox state
        if (isAnyConsentChecked) {
          jQuery('.c-subscribe__button, .c-subscribe__button__mobile')
            .removeClass('c-subscribe__button--disabled');
          jQuery('.c-subscribe__button button, .c-subscribe__button__mobile button')
            .prop('disabled', false);
        } else {
          jQuery('.c-subscribe__button, .c-subscribe__button__mobile')
            .addClass('c-subscribe__button--disabled');
          jQuery('.c-subscribe__button button, .c-subscribe__button__mobile button')
            .prop('disabled', true);
        }
      });
    }
  });
};

export default subscribeFormToggler;
