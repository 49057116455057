// Import Dependencies
import Blazy from 'blazy';
import imagesLoaded from 'imagesloaded';
import InfiniteScroll from 'infinite-scroll';
import jQuery from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

// Import app components
import arrowScroll from './components/arrow-scroll';
import focusSearchModalInput from './components/focus-search-modal-input';
import gdpr from './components/gdpr';
import menuToggler from './components/menu-toggler';
import { addHandlingForRequired, removeDisabledOptions } from './components/custom-select-extended';
import splitTextByLanguage from './components/splitTextByLanguage';
import stickyHeader from './components/sticky-header';
import subscribeFormToggler from './components/subscribe-form-toggler';

const secondaryNav = document.querySelector('.js-secondary-navigation');

// Run Application
const appModules = (() => {
  imagesLoaded.makeJQueryPlugin(jQuery);

  const blazy = new Blazy();

  let $grid;
  let masonry;

  if (document.querySelector('.js-masonry')) {
    // Convert some plugins to jQuery
    jQueryBridget('masonry', Masonry, jQuery);
    jQueryBridget('infiniteScroll', InfiniteScroll, jQuery);

    // Init masonry
    $grid = jQuery('.js-masonry');
    $grid.masonry();

    masonry = $grid.data('masonry');

    if (document.querySelector('.button__load-more')) {
      $grid.infiniteScroll({
        path: '.button__load-more',
        button: '.button__load-more',
        append: '.c-masonry__item',
        outlayer: masonry,
        history: false,
        scrollThreshold: false,
      });

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1 && node.matches('.c-masonry__item')) {
              if (document.documentElement.lang === 'zh') splitTextByLanguage(node);
            }
          });
        });
      });

      // pass in the target element, as well as the observer options
      observer.observe(document.querySelector('.js-masonry'), { childList: true, subtree: true });
    }
  }

  // Paginate search results
  if (document.querySelector('.js-search-results')) {
    // Convert some plugins to jQuery
    jQueryBridget('infiniteScroll', InfiniteScroll, jQuery);

    const searchResults = new InfiniteScroll(document.querySelector('.js-search-results'), {
      path: '.button__load-more',
      button: '.button__load-more',
      append: '.js-result',
      history: false,
      scrollThreshold: false,
    });

    searchResults.option({
      scrollThreshold: 400,
    });

    searchResults.on('append', (body, path, items) => {
      if (document.documentElement.lang === 'zh') items.forEach((item) => splitTextByLanguage(item));
    });
  }

  // Paginate projects listing
  if (document.querySelector('.js-projects-list')) {
    // Convert some plugins to jQuery
    jQueryBridget('infiniteScroll', InfiniteScroll, jQuery);

    const loadMoreButton = document.querySelector('.button__load-more');

    const projectsList = new InfiniteScroll(document.querySelector('.js-projects-list'), {
      path: '.button__load-more',
      button: '.button__load-more',
      append: '.js-result',
      history: false,
      scrollThreshold: 400,
    });

    projectsList.on('request', () => {
      if (loadMoreButton) loadMoreButton.classList.add('c-button--loading');
    });

    projectsList.on('append', (body, path, items) => {
      if (loadMoreButton) loadMoreButton.classList.remove('c-button--loading');

      if (document.documentElement.lang === 'zh') items.forEach((item) => splitTextByLanguage(item));

      items.forEach((item) => {
        const images = item.querySelectorAll('.js-lazyload');

        if (images.length > 0) images.forEach((image) => blazy.load(image));
      });
    });
  }

  // Initialise Blazy
  const lazyImageInstances = new Blazy({
    selector: '.js-lazyload',
    successClass: 'is-lazyloaded',
    errorClass: 'has-load-error',
    loadInvisible: true,
    breakpoints: [
      {
        width: 600, // max-width
        src: 'data-src-small',
      },
      {
        width: 900, // max-width
        src: 'data-src-medium',
      },
    ],
    offset: 100,
    success: (element) => {
      if (document.documentElement.lang === 'zh') splitTextByLanguage(element);

      InfiniteScroll.imagesLoaded = imagesLoaded;

      if ($grid) {
        // Much faster than alternate way
        $grid.imagesLoaded()
          .progress(() => {
            $grid.masonry('layout');
          });

        $grid.imagesLoaded(() => {
          const parent = element.parentNode;
          parent.className = parent.className.replace(/\bloading\b/, '');

          setTimeout(() => {
            lazyImageInstances.revalidate();
          }, 2000);
        });
      }
    },
  });

  if (document.querySelector('.button__load-more')) {
    const loadMoreButton = document.querySelector('.c-button.button__load-more');
    loadMoreButton.addEventListener('click', () => {
      setTimeout(() => {
        lazyImageInstances.revalidate();
      }, 2000);
    });
  }

  // Initialise custom scrollbar
  if (secondaryNav && window.matchMedia('(max-width: 600px)').matches) {
    import(/* webpackChunkName: "secondary-nav" */ 'simplebar')
      .then(({ default: Simplebar }) => {
        // eslint-disable-next-line no-new
        new Simplebar(secondaryNav, {
          autoHide: false,
          forceVisible: false,
          scrollbarMinSize: 25,
          scrollbarMaxSize: 0,
          direction: 'ltr',
          timeout: 1000,
        });
      });
  }

  // Initialise form
  if (document.querySelector('.js-campaignmonitor-subscribe')) {
    import(/* webpackChunkName: "campaignmonitor-subscribe" */ './components/CampaignMonitorSubscribe')
      .then(({ default: CampaignMonitorSubscribe }) => {
        CampaignMonitorSubscribe({
          selector: '.js-campaignmonitor-subscribe',
          signupMessage: '.js-campaignmonitor-subscribe-message',
        });
      });
  }

  // Initalise Modals
  if (document.querySelector('.js-modal-toggle')) {
    import(/* webpackChunkName: "modal" */ './components/Modal')
      .then(({ default: Modal }) => {
        Modal({
          selector: '.js-modal-toggle',
          closeButton: '.js-modal-close',
          modalInner: '.js-modal-inner',
          modalOpen: 'is-visible',
          showOverlay: 'has-overlay',
          modalIdAttr: 'data-modal-id',
        });
      });
  }

  // Initialise Tabs
  if (document.querySelector('.js-tabs')) {
    import(/* webpackChunkName: "tabs" */ './components/Tabs')
      .then(({ default: Tabs }) => {
        Tabs({
          selector: '.js-tabs',
          tabNavWrapper: '.js-tabs-nav-wrapper',
          tabNav: '.js-tab-nav',
          tabContent: '.js-tab-content',
          contentVisibleClass: 'is-visible',
          tabActiveClass: 'is-active',
        });
      });
  }

  // Initialise Manual Background Images
  if (document.querySelector('.js-manual-background-image')) {
    import(/* webpackChunkName: "manualBackgroundImage" */ './components/ManualBackgroundImage')
      .then(({ default: ManualBackgroundImage }) => {
        ManualBackgroundImage({
          selector: '.js-manual-background-image',
        });
      });
  }

  // Initalise Map
  if (document.querySelector('.js-location-map')) {
    import(/* webpackChunkName: "location-map" */ './components/LocationMap')
      .then(({ default: LocationMap }) => {
        LocationMap({
          selector: '.js-location-map',
          mapLarge: 'js-location-map-large',
          marker: '.js-location-map-marker',
          tooltip: '.js-location-map-tooltip',
          tooltipClass: 'c-location-map__tooltip',
          tooltipJsClass: 'js-location-map-tooltip',
          tooltipTitleClass: 'c-location-map__tooltip-title',
          toggleBtnForMap: '.js-toggle-btn-for-map',
          tile: '.js-location-map-tile',
          tileTitle: '.js-location-map-tile-title',
          tileAddress: '.js-location-map-tile-address',
          tileLocation: '.js-location-map-tile-location',
          mapboxAccessToken: 'pk.eyJ1IjoiaGFzc2VsbCIsImEiOiJjanh3b2pkZDAwNWl4M2dvemJiaWV3b3h1In0.RMquqBRqKwAs42J93ffEpA',
          colours: [
            { background: '#EBE317', color: '#2B33B0' },
            { background: '#75D957', color: '#2B33B0' },
            { background: '#4FEDCC', color: '#2B33B0' },
            { background: '#E31A70', color: '#4FEDCC' },
            { background: '#0DB8D9', color: '#2B33B0' },
            { background: '#72D4E6', color: '#E31A70' },
            { background: '#2B33B0', color: '#4FEDCC' },
            { background: '#2B33B0', color: '#EBE317' },
            { background: '#2B33B0', color: '#75D957' },
            { background: '#2B33B0', color: '#FDA7B3' },
            { background: '#E31A70', color: '#72D4E6' },
            { background: '#E31A70', color: '#4FEDCC' },
            { background: '#FDA7B3', color: '#2B33B0' },
            { background: '#A88F63', color: '#2B33B0' },
            { background: '#2B33B0', color: '#A88F63' },
            { background: '#B1B5B5', color: '#2B33B0' },
            { background: '#2B33B0', color: '#B1B5B5' },
          ],
        });
      })
      .then(() => import(/* webpackChunkName: "randomise-colours" */ './components/RandomiseColours'))
      .then(({ default: RandomiseColours }) => {
        // Initialise RandomiseColours
        RandomiseColours({
          selector: '.js-location-map',
          projectContainers: '.js-location-map-tooltip',
          colours: [
            { background: '#EBE317', color: '#2B33B0' },
            { background: '#75D957', color: '#2B33B0' },
            { background: '#4FEDCC', color: '#2B33B0' },
            { background: '#E31A70', color: '#4FEDCC' },
            { background: '#0DB8D9', color: '#2B33B0' },
            { background: '#72D4E6', color: '#E31A70' },
            { background: '#2B33B0', color: '#4FEDCC' },
            { background: '#2B33B0', color: '#EBE317' },
            { background: '#2B33B0', color: '#75D957' },
            { background: '#2B33B0', color: '#FDA7B3' },
            { background: '#E31A70', color: '#72D4E6' },
            { background: '#E31A70', color: '#4FEDCC' },
            { background: '#FDA7B3', color: '#2B33B0' },
            { background: '#A88F63', color: '#2B33B0' },
            { background: '#2B33B0', color: '#A88F63' },
            { background: '#B1B5B5', color: '#2B33B0' },
            { background: '#2B33B0', color: '#B1B5B5' },
          ],
        });
      });
  }

  // Initalise form
  if (document.querySelector('.js-form')) {
    import(/* webpackChunkName: "form" */ './components/Form')
      .then(({ default: Form }) => {
        Form({
          selector: '.js-form',
        });
      });
  }

  // Initalise Videos
  if (document.querySelector('.js-video')) {
    import(/* webpackChunkName: "video" */ './components/Video')
      .then(({ default: Video }) => {
        Video({
          selector: '.js-video',
          button: '.js-video-button',
        });
      });
  }

  // Initialise expandable
  if (document.querySelector('.js-expandable')) {
    import(/* webpackChunkName: "expandable" */ '@ramenjs/expandable')
      .then(({ default: Expandable }) => {
        Expandable({
          selector: '.js-expandable',
          button: '.js-expandable-button',
          contentPanel: '.js-expandable-content',
          openClass: 'is-open',
        });
      });
  }

  // DataLayerFormEvents
  if (document.querySelector('form')) {
    import(/* webpackChunkName: "data-layer-form-events" */ './components/DataLayerFormEvents')
      .then(({ default: DataLayerFormEvents }) => {
        DataLayerFormEvents({
          selector: 'form',
        });
      });
  }

  // Initialise Pardot Form Modal
  if (document.querySelector('.js-pardot-form-modal')) {
    import(/* webpackChunkName: "pardot-form-modal" */ './components/PardotFormModal')
      .then(({ default: PardotFormModal }) => {
        PardotFormModal({
          selector: '.js-pardot-form-modal',
        });
      });
  }

  // Image carousel
  if (document.querySelector('.js-card-carousel')) {
    import(/* webpackChunkName: "cardcarousel" */ '@ramenjs/cardcarousel')
      .then(({ default: CardCarousel }) => {
        CardCarousel({
          selector: '.js-card-carousel',
          wrapAround: false,
          contain: true,
          revalidateLazyImages: lazyImageInstances.revalidate,
          cellAlign: 'center',
          prevNextButtons: true,
          imagesLoaded: true,
          draggable: true,
          groupCells: true,
        });
      });
  }

  // Hero video behaviour
  if (document.querySelector('.js-hero')) {
    import(/* webpackChunkName: "hero-video" */ './components/hero-video')
      .then(({ default: heroVideo }) => {
        heroVideo();
      });
  }

  // Menu toggler
  menuToggler();

  // GDPR message
  gdpr();

  // Sticky header
  stickyHeader();

  // Subscribe form toggler
  subscribeFormToggler();

  // Load filters functionality
  if (document.querySelector('.c-filters')) {
    import(/* webpackChunkName: "filters" */ './components/filters')
      .then(({ default: filters }) => {
        filters();
      });
  }

  // Fire up lazyload for images
  lazyImageInstances.revalidate();

  // Fire up custom select
  if (document.querySelector('.c-form-select')) {
    import(/* webpackChunkName: "custom-select" */ 'custom-select')
      .then(({ default: customSelect }) => {
        customSelect('select', {
          containerClass: 'c-form-select__container',
          openerClass: 'c-form-select__opener',
          panelClass: 'c-form-select__panel',
          optionClass: 'c-form-select__option',
          optgroupClass: 'c-form-select__optgroup',
          isSelectedClass: 'is-selected',
          hasFocusClass: 'has-focus',
          isDisabledClass: 'is-disabled',
          isOpenClass: 'is-open',
        });
      })
      .then(() => {
        removeDisabledOptions();
        // TO-DO: move it to a separate import
        addHandlingForRequired();
      });
  }

  // Play full-width videos with autoplay set to 1
  if (document.querySelector('.c-fw-image')) {
    import(/* webpackChunkName: "full-width-video" */ './components/full-width-video')
      .then(({ default: fullWidthVideo }) => {
        fullWidthVideo();
      });
  }

  // Add target="_blank" to the agree to terms checkbox in the downloads modal form
  if (document.querySelector('.p-sprout-form')) {
    import(/* webpackChunkName: "agree-to-terms" */ './components/agree-to-terms')
      .then(({ default: agreeToTerms }) => {
        agreeToTerms();
      });
  }

  // Add a new interacting with buttons on touch
  if (document.querySelector('.c-button')) {
    import(/* webpackChunkName: "button-touch-styles" */ './components/button-touch-styles')
      .then(({ default: buttonTouchStyles }) => {
        buttonTouchStyles();
      });
  }

  if (document.querySelector('.js-dynamic-min-height')) {
    import(/* webpackChunkName: "dynamic-min-height" */ './components/dynamicMinHeight')
      .then(({ default: dynamicMinHeight }) => {
        dynamicMinHeight();
      });
  }

  if (document.querySelector('.js-marquee-content')) {
    import(/* webpackChunkName: "marquee" */ './components/marquee')
      .then(({ default: marqueeComponent }) => {
        marqueeComponent(jQuery('.js-marquee-content').html());
        jQuery('.js-marquee-content').remove();
      });
  }

  // Split text into latin and mandarin
  if (document.documentElement.lang === 'zh') splitTextByLanguage();

  // Scroll down on click when the cursor is an arrow
  arrowScroll();

  // Focus search modal input on search icon click
  focusSearchModalInput();

  if (document.querySelector('.js-slider')) {
    import(/* webpackChunkName: "marquee" */ './components/slider')
      .then(({ default: Slider }) => {
        Slider({
          selector: '.js-slider',
          slide: '.js-slide',
        });
      });
  }
})();

// Opt-in to Webpack hot module replacement
if (module.hot) module.hot.accept();

export default appModules;
